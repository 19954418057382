import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import LearnMore from "../templates/learnMore";
import office_image_1 from "../images/office_image_1.jpg";

function IndexPage() {
  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Private Offices"
      />
      <LearnMore
        title={`Private offices with
        Melbourne views`}
        cta={`Get in touch`}
        image={office_image_1}
      >
        <div>
          <p className="text-xl">
            If you have considered renting a dedicated space for your brand, you
            might be concerned about ongoing overheads, interior appearance and
            the anxiety-inducing commitment of yearly lease terms. Choosing to
            base your single or multiple private offices in a premium coworking
            space like collective_100 means flexibility where you need it the
            most, with flexible rental terms, incredible meeting rooms and
            breakout spaces, and facilities like showers, high speed Wi-Fi,
            staff amenities, office cleaning and more all factored into a single
            monthly fee.
          </p>
        </div>
        <div>
          <p className="text-xl pt-8 md:pt-0">
            There are many benefits associated with your collective_100 private
            office in Cremorne, with the ability to move straight into a
            spacious new office fully equipped with a desk, chair and lockable
            cabinet. Simply move in your required items, and you’re ready to
            begin the next chapter in comfortable, modern surroundings. Not only
            will you have access to your premium private office, but there are
            also casual spaces for creative breakaways as well as first class
            amenities you won’t find anywhere else.{" "}
          </p>
        </div>
      </LearnMore>
    </Layout>
  );
}

export default IndexPage;
